import PropTypes from 'prop-types';

const CustomPropTypes = { ...PropTypes };

CustomPropTypes.image = PropTypes.shape({
  sizes: PropTypes.object.isRequired
});

CustomPropTypes.fileImage = PropTypes.shape({
  localFile: PropTypes.shape({
    childImageSharp: CustomPropTypes.image.isRequired
  })
});

CustomPropTypes.location = PropTypes.shape({
  pathname: PropTypes.string.isRequired
});

export default CustomPropTypes;
