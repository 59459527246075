import React, { Component } from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import config from '../../data/config';
import PropTypes from '../PropTypes';

class SEO extends Component {
  static propTypes = {
    post: PropTypes.shape({
      title: PropTypes.string.isRequired,
      originalTitle: PropTypes.string,
      description: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      date: PropTypes.any.isRequired
    }),
    image: PropTypes.string.isRequired,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    themeColor: PropTypes.string
  };

  static defaultProps = {
    post: null,
    originalTitle: null,
    imageWidth: 1200,
    themeColor: '#000000',
    imageHeight: 630
  };

  render() {
    const { post, imageWidth, imageHeight, themeColor } = this.props;
    let { image } = this.props;
    let title;
    let description;
    let postURL;

    if (post) {
      title = post.title;
      title += post.originalTitle ? ` – ${post.originalTitle}` : '';
      description = post.description;
      postURL = config.siteUrl + config.pathPrefix + post.slug;
    } else {
      title = config.siteTitle;
      description = config.siteDescription;
    }

    const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix;
    image = config.siteUrl + realPrefix + image;

    const blogURL = config.siteUrl + config.pathPrefix;
    let schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: blogURL,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : ''
      }
    ];

    if (post) {
      schemaOrgJSONLD = schemaOrgJSONLD.concat([
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': postURL,
                name: title,
                image
              }
            }
          ]
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url: blogURL,
          name: title,
          alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image
          },
          description,
          author: 'Victor Raid',
          publisher: 'Dark Fields',
          datePublished: moment(new Date(post.date)).toISOString()
        }
      ]);
    }

    return (
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

        {/* OpenGraph tags */}
        <meta property="og:url" content={post ? postURL : blogURL} />
        <meta property="og:type" content={post ? 'article' : 'website'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {imageWidth && <meta property="og:image:width" content={imageWidth} />}
        {imageHeight && <meta property="og:image:height" content={imageHeight} />}
        <link rel="image_src" href={image} />
        <meta property="vk:image" content={image} />
        <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ''} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="theme-color" content={themeColor} />
      </Helmet>
    );
  }
}

export default SEO;
