import _ from 'lodash';
import React from 'react';
import Card from './Card';
import PropTypes from '../PropTypes';

class PostListing extends React.Component {
  getPostList() {
    return _.map(this.props.postEdges, postEdge => ({
      cover: _.get(postEdge.node, 'frontmatter.cover'),
      date: postEdge.node.frontmatter.date,
      description: postEdge.node.frontmatter.description,
      excerpt: postEdge.node.excerpt,
      slug: postEdge.node.slug,
      tags: postEdge.node.frontmatter.tags,
      timeToRead: postEdge.node.timeToRead,
      title: postEdge.node.frontmatter.title.text,
      top: postEdge.node.frontmatter.top,
      category: _.get(postEdge.node, 'frontmatter.category.document.data.name')
    }));
  }

  render() {
    const [topPosts, posts] = _.partition(this.getPostList(), {
      slug: this.props.featuredPost
    });

    return (
      <div className="posts">
        {topPosts.length > 0 && (
          <div className="container posts_top">
            <Card post={topPosts[0]} big />
          </div>
        )}
        <ul className="posts_list">
          {posts.map(post => (
            <li className="posts_item container" key={post.slug}>
              <Card post={post} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

PostListing.propTypes = {
  postEdges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape().isRequired
    })
  ).isRequired,
  featuredPost: PropTypes.string
};

PostListing.defaultProps = {
  featuredPost: undefined
};

export default PostListing;
