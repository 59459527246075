import React from 'react';
import { Link } from 'gatsby';
import map from 'lodash/map';
import classNames from 'classnames';
import PropTypes from '../PropTypes';

const menu = {
  movies: 'Фильмы',
  radio: 'Радио',
  library: 'Библиотека'
  // writings: 'Сочинения'
};

const propTypes = { currentCategory: PropTypes.string.isRequired };

const MenuList = ({ currentCategory }) => (
  <ul className="menu-list">
    {map(menu, (name, path) => (
      <li
        key={name}
        className={classNames('menu-list_item', {
          'menu-list_item__active': path === currentCategory
        })}
      >
        <Link to={`/${path}/`}>{name}</Link>
      </li>
    ))}
  </ul>
);

MenuList.propTypes = propTypes;

export default MenuList;
