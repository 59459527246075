module.exports = {
  pathPrefix: '/',
  blogPostDir: 'posts',
  siteTitle: 'Dark Fields — Записи о культуре, искусстве и тебе',
  siteTitleAlt: 'Обыденное и важное',
  siteLogo: {
    path: '/logos/seo_logo.png',
    width: 1200,
    height: 630
  },
  siteUrl: process.env.GATSBY_SITE_URL,
  siteDescription: 'И начало, и конец.',
  siteRss: '/rss.xml',
  googleAnalyticsID: 'UA-123403369-1',
  postDefaultCategoryID: 'random',
  userTwitter: 'dark_fields',
  dateFormFormat: 'DD.MM.YY',
  categoriesHash: {
    // TODO: add descriptions to categories
    movies: 'Фильмы',
    tvshows: 'Сериалы',
    radio: 'Радио',
    library: 'Библиотека'
  }
};
