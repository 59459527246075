import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from '../PropTypes';
import MenuList from './MenuList';

const propTypes = {
  logoFileImage: PropTypes.image.isRequired
};

function Header({ logoFileImage, ...restProps }) {
  return (
    <header className="header">
      <Link className="header_title" to="/" style={{ marginTop: '50px' }}>
        DARK FIELDS
      </Link>
      <MenuList {...restProps} />
    </header>
  );
}

Header.propTypes = propTypes;

export default Header;
