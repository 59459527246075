import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Link } from 'gatsby';
import moment from 'moment';
import PropTypes from '../PropTypes';

const Simple = props => <div {...props} />;

class Card extends React.Component {
  static propTypes = {
    post: PropTypes.shape({
      category: PropTypes.string,
      cover: PropTypes.fileImage.isRequired,
      description: PropTypes.string,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired,
    size: PropTypes.string,
    big: PropTypes.bool,
    inPage: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    big: false,
    inPage: false,
    className: null
  };

  render() {
    const { big, post, inPage, size, className } = this.props;
    const Component = inPage ? Simple : Link;
    const postMoment = moment(new Date(post.date));
    const dateFormat = moment().year() === postMoment.year() ? 'D MMMM' : 'MMMM YYYY';

    return (
      <div
        className={classNames(
          'card',
          { card__big: big, card__page: inPage },
          `card__${size}`,
          className
        )}
        style={{
          backgroundImage: `url(${_.get(post, 'cover.localFile.childImageSharp.sizes.src')})`
        }}
      >
        <Component className="card_background" to={`/${post.slug}`}>
          <div className="card_center">
            <h1 className="card_title">{post.title}</h1>
            <h3 className="card_description">{post.description}</h3>
          </div>
        </Component>
        <div className="card_info">
          {/* <Link to={`/${post.category}`}>
            </Link> */}
          {post.category}
          <span>{post.date && inPage && postMoment.format(dateFormat)}</span>
        </div>
      </div>
    );
  }
}

export default Card;
