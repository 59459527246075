import React, { Component } from 'react';
import Img from 'gatsby-image';
import Icon from './Icon';
import PropTypes from '../PropTypes';

const Image = props => (
  <Img
    className="more-groups_image"
    outerWrapperClassName="more-groups_image-wrapper"
    imgStyle={{ objectPosition: 'right' }}
    {...props}
  />
);

class SocialGroups extends Component {
  render() {
    const { images } = this.props;

    return (
      <div className="more-groups">
        <div className="more-groups_message">Быть рядом</div>
        <div className="more-groups_items">
          <a
            className="more-groups_item"
            href="https://t.me/darkfields"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image {...images.socialGroupTelegramImage.childImageSharp} />
            <Icon className="more-groups_icon" icon="telegram" color="white" />
          </a>
          <a
            className="more-groups_item"
            href="https://instagram.com/mydarkfields"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image {...images.socialGroupInstagramImage.childImageSharp} />
            <Icon className="more-groups_icon" icon="instagram" color="white" />
          </a>
          <a
            className="more-groups_item"
            href="https://vk.com/dark_fields"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image {...images.socialGroupVkImage.childImageSharp} />
            <Icon className="more-groups_icon" icon="vk" color="white" />
          </a>
        </div>
      </div>
    );
  }
}

SocialGroups.propTypes = {
  images: PropTypes.shape({
    socialGroupVkImage: PropTypes.fileImage.isRequired,
    socialGroupTelegramImage: PropTypes.fileImage.isRequired,
    socialGroupInstagramImage: PropTypes.fileImage.isRequired
  }).isRequired
};

export default SocialGroups;
