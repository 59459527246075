import React from 'react';
import PropTypes from '../PropTypes';

function Icon({ icon, color, className }) {
  return (
    <svg className={`icon icon__${icon} icon__${color} ${className}`}>
      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#icons-sprite_icon-${icon}`} />
    </svg>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
};

Icon.defaultProps = {
  color: '',
  className: null
};

export default Icon;
