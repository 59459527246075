import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from '../PropTypes';
import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';

import '../styles/index.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.location.isRequired
  // data: PropTypes.shape({
  //   backgroundImage: PropTypes.fileImage.isRequired,
  //   footerImage: PropTypes.fileImage.isRequired,
  //   logoImage: PropTypes.fileImage.isRequired,
  //   seoLogoImage: PropTypes.fileImage.isRequired
  // }).isRequired
};

function Layout({ children, location }) {
  const category = location.pathname.split('/')[1];

  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          logoImage: file(name: { eq: "ufo" }) {
            childImageSharp {
              sizes(maxWidth: 400, quality: 100, toFormat: PNG) {
                ...GatsbyImageSharpSizes_noBase64
              }
            }
          }
          seoLogoImage: file(name: { eq: "seo_logo" }) {
            childImageSharp {
              sizes(maxWidth: 1200, quality: 100, toFormat: JPG) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      `}
      render={data => (
        <div className="page">
          <SEO
            image={data.seoLogoImage.childImageSharp.sizes.src}
            imageWidth={1200}
            mageHeight={630}
          />
          <div className="page_container">
            <div className="noise" />
            <div className="page_header">
              <Header currentCategory={category} logoFileImage={data.logoImage.childImageSharp} />
            </div>
            <main className="main">{children}</main>
          </div>
          <Footer name="main" />
        </div>
      )}
    />
  );
}

Layout.propTypes = propTypes;

export default Layout;
